import $ from "jquery";

require('./../scss/main.scss')
require('bootstrap')

require('owl.carousel')

$(window).on('load', function () {
  $('html').removeClass('no-js')

  $('#slider').owlCarousel({
    margin: 15,
    nav: true,
    dots: false,
    autoplayTimeout: 5000,
    autoplay: true,
    loop: true,
    items: 3,
    responsive : {
      // breakpoint from 768 up
      768: {
        items: 5
      }
    }
  })

  $('#partners').owlCarousel({
    nav: false,
    dots: false,
    autoWidth:true,
    autoplayTimeout: 5000,
    autoplay: true,
    items: 1,
    responsive : {
      768: {
        items: 5
      },
      1024: {
        items: 7
      }
    }
  })
})
